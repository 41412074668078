import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MissionVisionPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Get Started" />
    <h1 style={styles.header}>Mission and Vision</h1>
    <div style={styles.body}>
      <p>
        Our Mission is to build life-long client relationships from a foundation of personal service, trust and an 
        emphasis on absolute returns on investments. To achieve this result, we provide a comprehensive plan, research, 
        ongoing guidance and direction, and proactive investment management.
      </p>
      <p>
        We are different from other financial planning and advisory groups because we are selective about the clients 
        we work with and offer an unparalleled level of personal service that is inclusive and family-oriented. Our 
        focus never wavers from what is important — you — our client — and how our successful working relationship can 
        affect your lifestyle and legacy. We believe that you worked hard for your money and that your money should 
        work hard for you.
      </p>
      <p>
        Our Vision for each Advisor-Client relationship is to:
      </p>
      <ul>
        <li>
          Provide an unparalleled level of personal service when it comes to our clients’ financial lives.
        </li>
        <li>
          Help our clients plan and manage their financial life so that they may succeed in realizing their goals and 
          dreams — for themselves and their beneficiaries.
        </li>
        <li>
          Create an investment strategy that provides our clients peace of mind in their financial life, regardless of 
          what the market does.
        </li>
        <li>
          Remain proactive so that we can help protect and grow our clients’ wealth.
        </li>
      </ul>
      <p>
        Our Goal is to become the most respected wealth advisory in the Region. Achieving this goal means that we have 
        done our job—we have helped our clients meet their life goals to the highest standard.
      </p>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default MissionVisionPage